const en = {
    logo: '../../assets/images/logo1-01.svg',
    classify:{ 
        title: [{title: 'Home',href:'/index',child: []},
                {title: 'About',href:'/about',child: [{title: 'Reviews',href:'/about_history'},{title: 'News',href:'/about_news'}]} ,
                {title: 'Committee',href:'',child: []},
                {title: 'Call For Paper',href:'/call',child: [{title: 'Paper Submission',href:''}]} ,
                {title: 'Registration',href:'',child: []} ,
                {title: 'Speaker',href:'/speaker',child: []} ,
                {title: 'Comtact',href:'',child: []} 

    ]
    }
}
export default en;
<template>

<div>
  <div class="preloader">
  <div class="preloader-inner">
  <div class="preloader-icon">
    <span></span>
    <span></span>
  </div>
  </div>
  </div>
  <myheader></myheader>
  <router-view></router-view>
  <myfooter></myfooter>

    <!-- <contentus></contentus> -->
  </div>
</template>

<script>
// import ProductPage from "./productpage.vue"
// import aboutpage from "./aboutpage.vue"
// import newspage from "./newspage.vue"
// import indexpage from "./indexpage.vue"


import myheader from "./components/header/Headertitle.vue"
import myfooter from "./components/footer/footer.vue"


// import contentus from "./pages/contentus.vue"


export default {
  components:{
    // ProductPage,
    // aboutpage,
    // newspage,
    // indexpage,
    myheader,
    myfooter
  

    // contentus

  }
}
</script>

<style >

li,ul{
  list-style: none;
}
</style>

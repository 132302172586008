<template>
    	<!-- <div class="footer"> -->
		<footer class="footer">
<div class="footer-bottom">
<div class="container">
<div class="inner">
<div class="row">
<div class="col-12">
<div class="left">
<p>Copyright &copy; 2024 Energy storage and smart power station key technology forum All rights reserved.</p>
</div>
</div>
</div>
</div>
</div>
</div>

</footer>
	<!-- </div> -->
</template>
  
<script>
  
  
export default {
   name:"MyFoot"
}
</script>
  
<style>
/* footer {
    height: 118px;
    width: 100%;
    background-color: #48382c;
}
footer div {
    width: 540px;
    margin: auto;
    height: 118px;
    overflow: hidden;
    

}

footer div P:first-child {
    color: white;  
    text-align: center;
    margin-top: 42px;
    font-size: 10px;
}

footer div P:last-child {
    color: #999999;
    font-size: 10px;
    margin-top: 13px;
    text-align: center;
} */
    
    

</style>
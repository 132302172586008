var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('banner',{attrs:{"bannerUrl":_vm.bannerUrl,"divStyle":_vm.divStyle,"imgStyle":_vm.imgStyle}}),_c('div',{staticClass:"com_zi clearfix"},[_c('div',{staticClass:"left_zi"},[_vm._m(0),_c('ul',{staticClass:"nav_left"},[_c('li',{class:{'active':_vm.type == 1}},[_c('a',{on:{"click":function($event){return _vm.checkType(1)}}},[_vm._v("在线监测设备")])]),_c('li',{class:{'active':_vm.type == 2}},[_c('a',{on:{"click":function($event){return _vm.checkType(2)}}},[_vm._v("便携检测设备")])]),_c('li',{class:{'active':_vm.type == 3}},[_c('a',{on:{"click":function($event){return _vm.checkType(3)}}},[_vm._v("卵囊藻自动扩培机")])])])]),_c('div',{staticClass:"right_zi"},[_c('div',{staticClass:"bar clearfix"},[_c('span',{staticClass:"b_tt"},[_vm._v(_vm._s(_vm.productName))]),_c('span',{staticClass:"curbar"},[_c('i',[_vm._v("您当前的位置：")]),_c('a',{on:{"click":function($event){return _vm.checkType(1)}}},[_vm._v("产品中心")]),_vm._v(" > "),_c('a',[_vm._v(_vm._s(_vm.productName))])])]),(_vm.type == 1)?_c('div',{staticClass:"pro_zi_show"},[_vm._m(1),_vm._m(2)]):_vm._e(),(_vm.type == 2)?_c('div',{staticClass:"pro_zi_show"},[_vm._m(3),_vm._m(4)]):_vm._e(),(_vm.type == 3)?_c('div',{staticClass:"pro_zi_show"},[_vm._m(5),_vm._m(6)]):_vm._e()])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top_com"},[_c('span',[_vm._v("产品展示")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pro_show1 clearfix"},[_c('div',[_c('h4',[_vm._v("Y53水产在线监测控制仪")]),_c('p',[_vm._v("远程、智能控制增氧机Y53溶氧套餐荧光法水产养殖专用。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pro_show2"},[_c('div',{staticClass:"top_3"},[_vm._v("产品详情")]),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../../assets/images/FktcOBSLnMwEDlZcGlgnVZEpP6oM.jpg")}}),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../../assets/images/FnxC1OO7INjJ_E_ZSPjKvxo14WNl.jpg")}}),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../../assets/images/Ft_3B7ZXIet81rmA1w3rzG8KB_GK.jpg")}}),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../../assets/images/Fo357NARgqkXGhDj2fkarnTl-Kp4.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pro_show1 clearfix"},[_c('div',[_c('h4',[_vm._v("P3便携式水质检测仪")]),_c('p',[_vm._v("P3产品是我司最新研发的便携式智能水质检测仪，主要针对水产养殖领域。该设备可检测十个水质指标，其中溶解氧、温度、pH通过设备蓝牙连接手机，在手机APP上可直接显示所测池塘的水质数据，数据可一键保存，数据云端存储不丢失，查询方便。设备所使用的荧光法溶解氧传感器采用业界领先的荧光淬息原理，检测精度高，抗干扰能力强，不受水流影响。另外七个指标氨氮、亚硝酸盐、总碱度、总硬度、钙离子、磷酸盐、生物量采用试剂检测、设备自动比色后APP端显示结果，避免人为比色造成的误差。设备携带方便，操作便携，检测数据云端存储，方便查询，是水产养殖的得力帮手。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pro_show2"},[_c('div',{staticClass:"top_3"},[_vm._v("产品详情")]),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../../assets/images/202312131722103c3045.jpg")}}),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../../assets/images/20231213172220286307.jpg")}}),_c('img',{staticStyle:{"width":"90%"},attrs:{"src":require("../../assets/images/Fvo_Ury3reePc2pT30HEBQXwk0Ie.jpg")}}),_c('img',{staticStyle:{"width":"90%"},attrs:{"src":require("../../assets/images/FvPDC7Pggp6uhW3jIhpPqkNyP9kS.jpg")}}),_c('img',{staticStyle:{"width":"90%"},attrs:{"src":require("../../assets/images/FmrCLarGz4fViOID8Mx1EGD7PDY6.jpg")}}),_c('img',{staticStyle:{"width":"90%"},attrs:{"src":require("../../assets/images/FuCpq-7xpEru6DD6Y8iLckR05upP.jpg")}}),_c('img',{staticStyle:{"width":"90%"},attrs:{"src":require("../../assets/images/FvVUH6sNzo6dH3LPJIJgz2OOfFH8.jpg")}}),_c('img',{staticStyle:{"width":"90%"},attrs:{"src":require("../../assets/images/FlG8QUaFAWtZ3sOkBpXXUcq0ZOLb.jpg")}}),_c('img',{staticStyle:{"width":"90%"},attrs:{"src":require("../../assets/images/Fnzi4WmnD3VtKi-DsTdtV-jlRBwJ.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pro_show1 clearfix"},[_c('div',[_c('h4',[_vm._v("卵囊藻自动扩培机")]),_c('p',[_vm._v(" 1) 培藻效率高，培藻效率为5天10倍，效率是其他产品的2倍以上； "),_c('br'),_vm._v(" 2) 自动化程度高，真正做到一键扩培； "),_c('br'),_vm._v(" 3) 桶体采用高分子复合材料，透光率高和使用寿命长，桶体寿命长达10年； "),_c('br'),_vm._v(" 4) 触摸屏设计，操作简单，查看方便。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pro_show2"},[_c('div',{staticClass:"top_3"},[_vm._v(" 产品详情 ")]),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../../assets/images/20200527162808d2214c.jpg")}})])
}]

export { render, staticRenderFns }
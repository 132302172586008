import request from '@/utils/request'

// 登录方法
export function login(username, password, code, uuid) {
  const data = {
    username,
    password,
    code,
    uuid
  }
  return request({
    url: '/login',
    method: 'post',
    data: data
  })
}

// 新闻列表
export function newsList(query) {
  return request({
    url: "/cmsApi/news/list",
    method: 'get',
    params: query
  })
}

// 新闻列表
export function newsDetail(query) {
  return request({
    url: "/cmsApi/news/detail",
    method: 'get',
    params: query
  })
}

// 分类列表
export function category() {
  return request({
    url: '/cmsApi/news/category',
    method: 'get'
  })
}

//会议历史
export function historyMeeting() {
  return request({
    url: '/cmsApi/news/historyMeeting',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    method: 'get'
  })
}

import Vue from 'vue';
import  Vuei18n   from 'vue-i18n';
// 引入自定义的各个语言配置文件
import zh from './config/zh';
import en from './config/en';

Vue.use(Vuei18n);
const default_lang = "zh"

const messages = {
    en,
    zh,
  }
const language = (navigator.language || 'zh').toLocaleLowerCase() // 这是获取浏览器的语言
const i18n = new Vuei18n ({
  locale: localStorage.getItem('lang') || language.split('-')[0] || default_lang, // 首先从缓存里拿，没有的话就用浏览器语言，
  messages, 
})


export default i18n

<template>
  <div id="top">
	<!-- <banner :bannerUrl="bannerUrl"></banner> -->
    <section class="hero-area">
<div class="hero-slider">

<!-- <div class="hero-inner overlay" style="background-image: url(../assets/images/banner01.png);"> -->
<div class="breadcrumbs overlay" :style="{backgroundImage: 'url(' + require('../assets/images/banner01.png') + ')' }">
<div class="container">
<div class="row align-items-center">
<div class="col-lg-8 offset-lg-2 col-md-12 col-12">
<div class="breadcrumbs-content">
<h1 class="page-title">演讲嘉宾</h1>
<p>2023年中国天津，首届储能与智慧电站关键技术论坛演讲嘉宾介绍</p>
</div>
<ul class="breadcrumb-nav">
<li><a style="cursor:pointer" @click="goToIndex()">首页</a></li>
<li>演讲嘉宾</li>
</ul>
</div>
</div>
</div>
</div>
</div> 
</section>


<section id="teachers" class="teachers section">
<div class="container">
<div class="row">

<div class="col-lg-6 col-md-6 col-12">
<div class="single-team wow fadeInUp" data-wow-delay=".2s">
<div class="row">
<div class="col-lg-5 col-12">

<div class="image">
<img src="../assets/images/metting_user1.png" alt="#">
</div>

</div>
<div class="col-lg-7 col-12">
<div class="info-head">

<div class="info-box">
<span class="designation">特邀嘉宾</span>
<h4 class="name"><a href="teacher-details.html">王成山</a></h4>
<p style="text-indent: 20px;">中国工程院院士。现任智能电网教育部重点实验室主任，微网与智能配电系统国家地方联合工程研究中心主任;是教育部长江学者特聘教授、国家杰出青年基金获得者、“国家特支计划”百千万工程领军人才;兼任教育部科学技术委员会委员，中国能源研究会常务理事，《IETEnergy SystemsIntegration》主编。</p>
</div>
</div>
</div>
</div>
</div>
</div>

<div class="col-lg-6 col-md-6 col-12">
<div class="single-team wow fadeInUp" data-wow-delay=".2s">
<div class="row">
<div class="col-lg-5 col-12">

<div class="image">
<img src="../assets/images/metting_user2.png" alt="#">
</div>

</div>
<div class="col-lg-7 col-12">
<div class="info-head">

<div class="info-box">
<span class="designation">主讲嘉宾</span>
<h4 class="name"><a href="teacher-details.html">王正伟</a></h4>
<p style="text-indent: 20px;">清华大学流体机械及工程研究团队负责人，长聘教授，博士生导师。IAHR 水力机械与系统方向学术委员会委员及亚洲分会主席，中国动力工程学会水轮机专业委员会副主任委员，全国水轮机标准化技术委员会委员，中国可再生能源学会理事，中国可再生能源学会海洋能专业委员会副主任委员。</p>
</div>
</div>
</div>
</div>
</div>
</div>

<div class="col-lg-6 col-md-6 col-12">
<div class="single-team wow fadeInUp" data-wow-delay=".2s">
<div class="row">
<div class="col-lg-5 col-12">

<div class="image">
<img src="../assets/images/metting_user03.png" alt="#">
</div>

</div>
<div class="col-lg-7 col-12">
<div class="info-head">

<div class="info-box">
<span class="designation">主讲嘉宾</span>
<h4 class="name"><a href="teacher-details.html">刘新天</a></h4>
<p style="text-indent: 20px;">哈尔滨电机厂有限责任公司副总工程师、副总设计师/技术专家，正高级工程师。
    主要研究方向:水轮发电机设计、初步设计、现场安装问题处理，调试问题处理等;曾主持并设计湖北水布垭发电机460MW,
    曾荣获黑龙江省政府颁发科技技术进步奖二等奖;中国水力发电工程学会科技技术进步奖二等奖。</p>
</div>
</div>
</div>
</div>
</div>
</div>

<div class="col-lg-6 col-md-6 col-12">
<div class="single-team wow fadeInUp" data-wow-delay=".2s">
<div class="row">
<div class="col-lg-5 col-12">

<div class="image">
<img src="../assets/images/metting_user04.png" alt="#">
</div>

</div>
<div class="col-lg-7 col-12">
<div class="info-head">

<div class="info-box">
<span class="designation">主讲嘉宾</span>
<h4 class="name"><a href="teacher-details.html">郭伟</a></h4>
<p style="text-indent: 20px;">天津仁爱学院机械工程学院院长，天津大学机械工程学院长聘教授、机械工程及管理科学工程博士生导师，
    博士，天津大学内燃机与工程热物理博士后流动站博士后，香港科技大学工业工程与工程管理系博士后。科技部制造业信息化科技工程总体专家组专家，
    中国机械工程学会工业工程师资格认证专家组专家。</p>
</div>
</div>
</div>
</div>
</div>
</div>

<div class="col-lg-6 col-md-6 col-12">
<div class="single-team wow fadeInUp" data-wow-delay=".2s">
<div class="row">
<div class="col-lg-5 col-12">

<div class="image">
<img src="../assets/images/metting_user05.png" alt="#">
</div>

</div>
<div class="col-lg-7 col-12">
<div class="info-head">

<div class="info-box">
<span class="designation">主讲嘉宾</span>
<h4 class="name"><a href="teacher-details.html">李明涛</a></h4>
<p style="text-indent: 20px;">博士，西安交通大学新能源科学与工程系副教授，博士生导师。现任中国节能协会公共机构节约能源资源专业委员会委员、
    中国化工学会过程模拟及仿真专业委员会青年委员、陕西省电力新能源及电动交通标准化委员会委员。
    开源组织Ai4Energy(https://github.com/ai4energy)创始人。</p>
</div>
</div>
</div>
</div>
</div>
</div>

<div class="col-lg-6 col-md-6 col-12">
<div class="single-team wow fadeInUp" data-wow-delay=".2s">
<div class="row">
<div class="col-lg-5 col-12">

<div class="image">
<img src="../assets/images/metting_user06.png" alt="#">
</div>

</div>
<div class="col-lg-7 col-12">
<div class="info-head">

<div class="info-box">
<span class="designation">主讲嘉宾</span>
<h4 class="name"><a href="teacher-details.html">孙江</a></h4>
<p style="text-indent: 20px;">大会主席、天津仁爱学院领军人才、教授、博士、天发总厂董事长、深圳恒发水电公司总经理。
    天津首批“优秀企业家培养工程”等人才工程。天津仁爱学院领军人才。曾荣获天津市五一劳动奖章、中国机械工业优秀企业家等荣誉称号。
    主持国家级、省部级科研创新项目12项，其中EI以上6篇，实用新型、发明专利近50项。</p>
</div>
</div>
</div>
</div>
</div>
</div>

<div class="col-lg-6 col-md-6 col-12">
<div class="single-team wow fadeInUp" data-wow-delay=".2s">
<div class="row">
<div class="col-lg-5 col-12">

<div class="image">
<img src="../assets/images/metting_user07.png" alt="#">
</div>

</div>
<div class="col-lg-7 col-12">
<div class="info-head">

<div class="info-box">
<span class="designation">主讲嘉宾</span>
<h4 class="name"><a href="teacher-details.html">霍乾涛</a></h4>
<p style="text-indent: 20px;">南瑞集团有限公司电控分公司副总经理，高级工程师。
    长期从事发电机励磁、调相机控制、新能源主动支撑等方面的研究工作，
    火电机组励磁系统等研制、设计及现场试验工作。提出了数字离散化控制模型的精确化方法，完成模型测试与入网检测等，
    并通过CE认证。历获中国电机工程学会中国电力科学技术奖一等奖。</p>
</div>
</div>
</div>
</div>
</div>
</div>

<div class="col-lg-6 col-md-6 col-12">
<div class="single-team wow fadeInUp" data-wow-delay=".2s">
<div class="row">
<div class="col-lg-5 col-12">

<div class="image">
<img src="../assets/images/metting_user08.png" alt="#">
</div>

</div>
<div class="col-lg-7 col-12">
<div class="info-head">

<div class="info-box">
<span class="designation">标准宣贯嘉宾</span>
<h4 class="name"><a href="teacher-details.html">周林</a></h4>
<p style="text-indent: 20px;">澳大利亚RIZNER有限公司执行董事，中国水力发电工程学会继保与励磁专委会委员，澳大利亚工程师协会会员MIEAuSt。
    周林长期从事电力系统保护控制及在线监测方面的研究工作，获电弧光保护相关专利技术7项;近期重点项目包括三峡集团的乌东德和白鹤滩巨型水电站的应用。</p>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="col-lg-6 col-md-6 col-12">
<div class="single-team wow fadeInUp" data-wow-delay=".2s">
<div class="row">
<div class="col-lg-5 col-12">

<div class="image">
<img src="../assets/images/metting_user09.png" alt="#">
</div>

</div>
<div class="col-lg-7 col-12">
<div class="info-head">

<div class="info-box">
<span class="designation">标准宣贯嘉宾</span>
<h4 class="name"><a href="teacher-details.html">罗志昭</a></h4>
<p style="text-indent: 20px;">广东日昭电工有限公司董事长，系节能铜包铝管母线第一发明人。
专业从事节能母线与绝缘配合的开发设计，研究与应用。发明了节能铜包铝管母线技术，获得国内外授权发明专利100多项。获得中国专利优秀奖2项等荣誉，
主持制定企业标准2项，地方标准2项，团体标准2项。</p>
</div>
</div>
</div>
</div>
</div>
</div>






</div>
</div>
</section>
<a href="javascript:void(0)" class="scroll-top btn-hover" @click="scrollToAnchor('top')">
<i class="lni lni-chevron-up"></i>
</a>
  </div>
</template>
  
<script>
 
  
  import indexnav from "@/components/nav/indexnav.vue"
  import banner from "@/components/header/banner.vue"


export default {
   name:"IndexPage",
   components:{
    indexnav,
	banner
   },
   data(){
    return{
		bannerUrl:["BANNER.png"]
    }
   },
   mounted(){
  	//  TouchSlide({ 
	// 		slideCell:"#news_slide",
	// 		mainCell:".bd ul",
	// 		effect:"leftLoop",
	// 		autoPlay:true//自动播放
	//  });
    const slide = document.querySelector(".hero-slider");
    console.log(slide)
	   tns({
            container: '.hero-slider',
            items: 1,
            slideBy: 'page',
            autoplay: false,
            mouseDrag: true,
            gutter: 0,
            nav: true,
            controls: false,
            controlsText: ['<i class="lni lni-arrow-left"></i>', '<i class="lni lni-arrow-right"></i>'],
        });
	 document.addEventListener('click', evnt => {
		let anchor = evnt.target.closest('a[href^="#"]')
		if (anchor) {
			evnt.preventDefault()
			let value = anchor.getAttribute('set_data');
		    if(value){
 			  this.newsDetail(value)
			}
           
        }
        })
 },
 methods: {
    scrollToAnchor(id){
        var anchor = document.getElementById(id);
        anchor.scrollIntoView();
    },
    goToIndex(){
       this.$router.push({
          path:"/index"
        })
    },
	 newsDetail(index){
		console.log(11111)
        this.$router.push({
          path:"/newsDetail/"+index
        })
     },
	 caseDetail(type){
     this.$router.push({
          path:"/caseDetail/"+type
      })
    }
 }
    
}
</script>
  
<style>

    
    

</style>
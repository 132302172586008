<template>
  <!--移动端底部导航-->
  <div>
  <div class="phonefooternav">
		<ul class="clearfix">
			<li>
				<a href="tel:027-59764323">
					<i class="iconfont">&#xe60c;</i>
					<span>一键电话</span>
				</a>
			</li>
			<li>
				<a @click="tocontentus">
					<i class="iconfont">&#xe61d;</i>
					<span>联系我们</span>
				</a>
			</li>
			<li>
				<a  @click="toindex" >
					<i class="iconfont">&#xe607;</i>
					<span>返回首页</span>
				</a>
			</li>
		</ul>
	</div><!--移动端  Mmenu-->
	<!--移动端  Mmenu-->
	<nav id="mmenu">
		<ul>
			<!-- <li><a @click="toindex">首 页</a></li> -->
			<li><a @click="toindex" href="#">首 页</a></li>
			<li><a @click="toabout" href="#">关于我们</a></li>
			<li><a @click="toproduct(1)" href="#">产品中心</a>
				<ul>
					<li><a @click="toproduct(1)" href="#">在线监测设备</a></li>
					<li><a @click="toproduct(2)" href="#">便携检测设备</a></li>
					<li><a @click="toproduct(3)" href="#">卵囊藻自动扩培机</a></li>
				</ul>
			</li>
			
			<li><a @click="todata" href="#">数据中心</a>
			</li>
			<li><a @click="tocase(1)" href="#">工程案例</a>
				<ul>
					<li><a @click="tocase(1)" href="#">虾稻共作</a></li>
					<li><a @click="tocase(2)" href="#">高位池养殖</a></li>
					<li><a @click="tocase(3)" href="#">河蟹精养</a></li>
				</ul>
				
			</li>
			
			<li><a @click="tonew(1)" href="#">新闻中心</a>
				<ul>
					<li><a @click="tonew(1)" href="#">公司动态</a></li>
					<li><a @click="tonew(2)" href="#">行业新闻</a></li>
				</ul>
			</li>
			<li><a  @click="tocontentus"  href="#">联系我们</a>
				
			</li>
		</ul>
	</nav>	
</div>
</template>
  
<script>
  
export default {
   name:"IndexNav",
   data(){
    return {
       
    }
   },
   mounted() {
        // new Mmenu(document.querySelector('#menu'))

        document.addEventListener('click', evnt => {
            let anchor = evnt.target.closest('a[href^="#"]')
            if (anchor) {
                evnt.preventDefault()
            }
        })
    },
	methods:{
  		toindex(){
      		this.$router.push({
          	path:"/index"
      	})
  
  	},
  	toproduct(type){
      	if(!type){
        	type =1;
      	}
      	console.log(type)
      	this.$router.push({
          path:"/product/"+type
      	})
  	},
  	tonew(type){
		if(!type){
        	type =1;
      	}
      this.$router.push({
          path:"/new/"+type
      })
      
  	},
  	toabout(){
      this.$router.push({
          path:"/about"
      })
      
  	},
  	tocontentus(){
        this.$router.push({
            path: "/contentus"
        })
  	},
  	todata(){
    	this.$router.push({
            path: "/data"
        })
  	},
  	tocase(type){
    	this.$router.push({
            path: "/case/"+type
        })
  	},

 },
    
}
</script>
  
<style scoped>
.lunbo{
    width: 100%;
    
    background-position: center center;
    height: 867px;
    background-repeat: no-repeat;
    margin-top: -33px;


}
    
    

</style>
const zh = {
    logo: '../../assets/images/logo1-02.svg',
    classify:{ 
    	title: [{title: '主页',href:'/index',child: []},
                {title: '关于',href:'newsHis',child: [{title: '历届回顾',href:'/newsHis'},{title: '最新动态',href:'/newsHisNews'}]} ,
                {title: '组委会',href:'',child: []},
                {title: '会议征稿',href:'/call',child: [{title: '论文提交',href:''}]} ,
                {title: '注册参会',href:'',child: []} ,
                {title: '演讲嘉宾',href:'/speaker',child: []} ,
                {title: '联系我们',href:'',child: []} 
                
        ]     

    }
}
export default zh;